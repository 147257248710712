<template>
	<div class="main-wrapper mt-4">
		<CRow>
			<CCol
				col="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>
					General info
				</h4>
			</CCol>
			<CCol col="12">
				<CInput
					v-model="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Accordion Set Name*"
				/>
			</CCol>
		</CRow>
		<hr class="my-5">

		<CRow class="mb-5">
			<CCol col="12">
				<div class="list-sku">
					<label class="mb-0">
						<h3 class="typo-label">Include SKU*</h3>
						<p class="typo-caption color-black-45">Add SKU product need to show attribute.<br> It will be displayed in product detail specification section.</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-sku'].open()"
					>
						{{ `${formData.products.length ? 'Edit' : 'Add'} SKU` }}
					</CButton>
				</div>
				<div v-if="!formData.products.length" class="mb-3">
					<BaseNoItemBanner
						:class="{ 'is-invalid': $v.formData.products.$error }"
						text="There are no SKU"
					/>
					<div v-if="$v.formData.products.$error" class="invalid-feedback">
						{{ $t('global.error.required') }}
					</div>
				</div>
				<ul v-else class="list-item show-border">
					<p class="show-text">
						{{ formData.products.join('; ') }}
					</p>
				</ul>

				<ModalSkuList
					ref="modal-sku"
					title="Select SKUs"
					:sku-list="formData.products"
					@onConfirm="handleSKUChange"
				/>
			</CCol>
		</CRow>

		<CRow>
			<CCol md="12">
				<div class="d-block">
					<label class="mb-0">
						<h3 class="typo-label">Accordion*</h3>
						<p class="typo-caption color-black-45">Add Accordion and drag to reposition. It will be<br> displayed in product detail specification section.</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-add-keys'].open()"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Add Accordion
					</CButton>
				</div>
			</CCol>
			<CCol lg="12 mt-4">
				<div v-if="!formData.keys.length" class="mb-3">
					<BaseNoItemBanner
						:class="{ 'is-invalid': $v.formData.keys.$error }"
						text="There are no accordion in this set"
					/>
					<div v-if="$v.formData.keys.$error" class="invalid-feedback">
						{{ $t('global.error.required') }}
					</div>
				</div>
				<AttributeSetEditKeys
					v-else
					v-model="formData.keys"
					@onChange="handleKeyChange"
				/>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:is-edit="isEdit"
			:remove-text="removeText"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
			@onRemove="handleRemove"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteAccordionSet.bind(null, $route.params.id)"
			title="Remove this accordion"
			description="Are you sure you want to delete this accordion?"
			@onSuccess="$router.push({ name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_SET_LIST })"
		/>

		<AttributeSetEditModalKeys
			ref="modal-add-keys"
			modal-title="Add Accordion"
			:selected-keys="formData.keys"
			:is-description="false"
			:attribute-keys="accordionKeys.data"
			@onAdd="handleAddKeys"
		/>
	</div>
</template>

<script>
import ModalSkuList from '@/components/ModalSkuList.vue';
import AttributeSetEditKeys from '@/components/AttributeSetEditKeys.vue';
import AttributeSetEditModalKeys from '@/components/AttributeSetEditModalKeys.vue';
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { scrollToTop } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'AccordionSetsForm',
	components: { AttributeSetEditModalKeys, AttributeSetEditKeys, ModalSkuList },

	validations: {
		formData: {
			name: {
				required,
			},
			products: {
				required,
			},
			keys: {
				required,
			},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formData: {
				name: null,
				products: [],
				keys: [],
			},
			disabledResend: false,
			ROUTE_NAME,
			isSubmitting: false,
		};
	},

	computed: {
		...mapState('accordionSets', {
			accordionSetsEdit: 'edit',
		}),

		...mapState('accordionKeys', {
			accordionKeys: 'list',
		}),

		removeText() {
			return this.isEdit ? 'Remove Accordion set' : '';
		},
	},

	async created() {
		await this.getAccordionKeys({ per_page: 'all' });
		if (this.isEdit) {
			this.formData = this.accordionSetsEdit.data;
		}
	},

	methods: {
		...mapActions({
			getAccordionKeys: 'accordionKeys/getAccordionKeys',
			getAccordion: 'accordionSets/getAccordionSet',
			createAccordionSet: 'accordionSets/createAccordionSet',
			updateAccordionSet: 'accordionSets/updateAccordionSet',
			deleteAccordionSet: 'accordionSets/deleteAccordionSet',
		}),

		async handleConfirm() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}
			this.isSubmitting = true;
			if (!this.isEdit) {
				await this.createAccordionSet(this.formData);
				this.isSubmitting = false;
				this.$router.push({ name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_SET_LIST });
				return;
			}
			await this.updateAccordionSet({ id: this.$route.params.id, params: this.formData });
			this.isSubmitting = false;
		},
		handleRemove() {
			this.$refs['modal-remove'].open();
		},
		handleCancel() {
			this.$router.push({ name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_SET_LIST });
		},
		handleSKUChange(skus) {
			this.formData.products = skus;
		},
		handleKeyChange(keys) {
			this.formData.keys = keys;
		},
		handleAddKeys(keyIds = []) {
			const newKeys = this.accordionKeys.data.filter((key) => keyIds.some((id) => id === key.id));
			this.formData.keys = [...newKeys, ...this.formData.keys];
		},
	},
};
</script>

<style scoped lang="scss">
.main-wrapper {
	margin-bottom: rem(48);
}

.list-sku {
	display: block;
	margin-bottom: rem(16);
}

.list-item {
	padding: rem(20) rem(16) 0;
	max-height: rem(320);
	overflow: auto;
}

.show-text {
	font-size: rem($font-size-caption);
	font-weight: $font-weight-semi-bold;
	color: $color-black-45;
}

.show-border {
	border: solid 1px $color-gray-400;
	border-radius: rem(4);
}
</style>
